@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

/* chỉnh chỗ giờ màn thuê game */
.ant-statistic-content {
  color: white !important;
  font-weight: bold !important;
  font-size: 14px !important;
  @media (min-width: 1000px) {
    font-size: 16px !important;
  }
  @media (min-width: 1400px) {
    font-size: 16px !important;
  }
  @media (min-width: 1920px) {
    font-size: 18px !important;
  }
}
/* Thumbnail */
.image-gallery-thumbnail img,
.image-gallery-thumbnail.active img,
.image-gallery-thumbnail:hover img {
  border: none !important;
  outline: none !important;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}

.image-gallery-thumbnail.active img,
.image-gallery-thumbnail:hover img {
  opacity: 1;
}

.custom-thumbnail {
  border: none !important;
  outline: none !important;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}

.custom-thumbnail:hover,
.custom-thumbnail.active {
  opacity: 1;
}

.rent-countdown .ant-statistic-content-value {
  font-size: 14px;

  @media (max-width: 1400px) {
    font-size: 12px !important;
  }
  @media (max-width: 1000px) {
    font-size: 12px !important;
  }
}

/* Trang account page */
.custom-tabs .ant-tabs-ink-bar {
  display: none !important;
}
.custom-tabs .ant-tabs-content-holder {
  display: none;
}
.custom-tabs .ant-tabs-tab-active,
.custom-tabs .ant-tabs-tab:hover {
  border-bottom: none !important;
}

.custom-tabs .ant-tabs-tab {
  padding-bottom: 0 !important;
  margin: 0 !important;
}
:where(.css-dev-only-do-not-override-m4timi).ant-tabs-left
  > .ant-tabs-content-holder,
:where(.css-dev-only-do-not-override-m4timi).ant-tabs-left
  > div
  > .ant-tabs-content-holder
  .custom-tabs
  .ant-tabs-content-holder {
  margin-left: 0 !important;
  border-left: none !important;
}
/* Giờ còn lại của vip */
.custom-countdown .ant-statistic-content-value {
  font-size: 14px !important;
}
